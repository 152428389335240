<template>
    <div class="spinner" :style="{height, width}">
<!--        <div class="spinner__text">{{timer}}</div>-->
    </div>
</template>

<script>
export default {
    name: "GoldSpinner",
    props: {
        height: {
            type: [String, Number],
            required: false,
            default: '75px'
        },
        width: {
            type: [String, Number],
            required: false,
            default: '75px'
        },
    },
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    background: $yellow-main;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    position: relative;
    margin-top: 30px;
    &__text {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        font-weight: 600;
        z-index: 50;
        color: $yellow-main;
        animation-play-state: paused;
    }
    &:before,
    &:after {
        content: '';
        position: absolute;
    }
    &:before {
        animation: rotate 1s linear infinite;
        border-radius: 50%;
        background: linear-gradient(0deg, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 100%, 0.9) 100%) 0% 0%,
        linear-gradient(90deg, hsla(0, 0%, 100%, 0.9) 0%, hsla(0, 0%, 100%, 0.6) 100%) 100% 0%,
        linear-gradient(180deg, hsla(0, 0%, 100%, 0.6) 0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%,
        linear-gradient(360deg, hsla(0, 0%, 100%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
        background-repeat: no-repeat;
        background-size: 50% 50%;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
    }

    &:after {
        background: white;
        border-radius: 50%;
        top: 5%;
        bottom: 5%;
        left: 5%;
        right: 5%;
    }
}
</style>
