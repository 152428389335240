<template>
    <div class="test-timer">
        <gold-spinner
            :height="200"
            :width="200"
        />
    </div>
</template>

<script>
import GoldSpinner from "@/components/GoldSpinner";
export default {
    name: "Loading",
    components: {GoldSpinner}
}
</script>

<style scoped>

</style>
